<template>
    <v-row>
        <v-col cols="12">
            <v-card>
                <v-toolbar color="primary" dark flat height="auto">
                    <v-toolbar-title class="py-2">Todas as faturas</v-toolbar-title>
                </v-toolbar>
                <FinanceiroFaturaListar :filtro="filtro" />
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import FinanceiroFaturaListar from "@/Views/Financeiro/Component/FinanceiroFaturaListar"

    export default {
        name: "FinanceiroFatura",
        components: {FinanceiroFaturaListar},
        data() {
            return {
                filtro : {}
            }
        }
    }
</script>

<style scoped>

</style>

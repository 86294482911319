<template>
    <div>
        <v-progress-linear indeterminate absolute color="secondary" :active="carregando" />
        <v-simple-table v-if="faturas.total > 0">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>Franquia</th>
                        <th>Vencimento</th>
                        <th>Valor total</th>
                        <th>Pagamento</th>
                        <th>Boleto</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(f, i) in faturas.lista" :key="i">
                        <td>
                            <strong>{{f.razao_social_nome_completo}}</strong>
                            <br /><small><strong>ID:</strong> {{f.id_fatura}}</small>
                        </td>
                        <td>
                            {{dataPt(f.dt_vencimento)}}<br />
                            <small>Referente ao mês <strong>{{f.dt_referencia}}</strong></small>
                        </td>
                        <td>R$ {{numero(f.valor_total)}}</td>
                        <td>{{f.dh_pagamento != null ? dataPt(f.dh_pagamento) : 'Pagamento pendente'}}</td>
                        <td><v-btn v-if="!f.dh_pagamento" icon @click="documentoDownload(f.id_boleto)"><v-icon>mdi-download</v-icon></v-btn></td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-alert v-else type="info" color="blue-grey" text class="mb-0">Registros não encontrados</v-alert>
        <v-divider/>
        <Paginacao :disabled="carregando" @paginar="paginar" :total="faturas.total" />
    </div>
</template>

<script>

import Paginacao from '@/Views/Estrutura/Paginacao'
import axios from "axios"
import {mapState} from "vuex"

export default {
    name: "FinanceiroFaturaListar",
    props : ['filtro'],
    components: {Paginacao},
    data() {
        return {
            carregando : false,
            faturas : {
                lista : [],
                total : 0
            }
        }
    },
    computed : {
        ...mapState(['apiUrl', 'paginationLimit'])
    },
    methods : {
        // paginate
        paginar(pagina) {
            this.carregando = true
            return axios.post(`${this.apiUrl}fatura/listar`, {
                pagina,
                limit       : this.paginationLimit,
                offset      : this.paginationLimit * pagina,
                id_franquia : parseInt(this.filtro.id_franquia) || null
            }).then( (res) => {
                this.faturas = res.data
                this.carregando = false
            })
        },
        documentoDownload(id_boleto) {
            this.carregando = true
            return axios.post(`${this.apiUrl}fatura/boleto/get`, {id_boleto}).then( (res) => {
                this.downloadBase64(res)
                this.carregando = false
            })
        },
    },
    watch : {
        'filtro.busca' : function () {
            this.paginar(0)
        },
        'filtro.id_franquia' : function () {
            this.paginar(0)
        }
    },
    activated() {
        this.paginar(0)
    },
    created() {
        this.paginar(0)
    }
}
</script>

<style scoped>

</style>
